const BoDuanList = [
  {
    label: '365波段',
    value: ['0810A', '1030A', '1030B', '1214A']
  },
  {
    label: '380波段',
    value: [
      '0810A', '0810B', '1016A',
      '1016B', '1016C', '1016D',
      '1016E', '1214A', '1214B',
      '1030A', '1030B', '1735A',
      '2235A', '2235B', '4545A',
      '4545B', '4545C', '0922A'
    ]
  },
  {
    label: '395波段',
    value: [
      '0810A', '0810B', '1016A',
      '1016B', '1016C', '1016D',
      '1016E', '1214A', '1214B',
      '1030A', '1030B', '1735A',
      '2235A', '2235B', '4545A',
      '4545B', '4545C', '0922A','3232A'
    ]
  },
  {
    label: '410波段',
    value: [
      '0810A', '0810B', '1016A',
      '1016B', '1016C', '1016D',
      '1016E', '1214A', '1214B',
      '1030A', '1030B', '1735A',
      '2235A', '2235B', '4545A',
      '4545B', '4545C', '0922A'
    ]
  },
  {label: '蓝光', value: ['2828A', '4040A', '4545A']},
  {label: '绿光', value: ['2020A', '2020A旧', '2020B','1217A', '2828A', '4040A', '4545A','4545B']},
  {label: '黄绿光', value: ['14F']},
  {label: 'RGB红光', value: ['06BUA', '06CUA', '16BUS','26CUA','32BUS','40BUA','12CUA','1616D']},
  {label: 'RGB绿光', value: ['0507A', '0608A']},
  {label: 'RGB蓝光', value: ['0507A', '0608A']},
]

const ZhuangTaiList = [
  {label: '在库', value: '在库'},
  {label: '出库', value: '出库'},
  {label: '隔离', value: '隔离'},
  {label: '降档', value: '降档'},
  {label: '报废', value: '报废'}
]

const LaiYuanList = [
  {label: '代工', value: '代工'},
  {label: '自产', value: '自产'},
  {label: '采购', value: '采购'}
]

const ZXDongZuoList = [
  {label: '无', value: '无'},
  {label: '退货', value: '退货'},
  {label: '客诉', value: '客诉'},
  {label: '换货', value: '换货'}
]

const CKDongZuoList = [
  {label: '销售', value: '销售'},
  {label: '样品', value: '样品'},
  {label: '测试', value: '测试'},
  {label: '其他', value: '其他'}
]

const squarePvRanges = [
  {
    '型号': '365波段0810A',
    '取值': {
      '电压': '3.2-4',
      '电压步长': 0.1,
      '波长': '365-380',
      '波长步长': 2.5,
      '亮度': '1-12',
      '亮度步长': 1
    }
  },
  {
    '型号': '365波段1030A',
    '取值': {
      '电压': '3.2-4',
      '电压步长': 0.2,
      '波长': '365-380',
      '波长步长': 2.5,
      '亮度': '10-120',
      '亮度步长': 10
    }
  },
  {
    '型号': '365波段1030B',
    '取值': {
      '电压': '3.2-4',
      '电压步长': 0.2,
      '波长': '365-380',
      '波长步长': 2.5,
      '亮度': '10-120',
      '亮度步长': 10
    }
  },
  {
    '型号': '365波段1214A',
    '取值': {
      '电压': '3-3.6',
      '电压步长': 0.1,
      '波长': '365-380',
      '波长步长': 2.5,
      '亮度': '1-20',
      '亮度步长': 1
    }
  },
  {
    '型号': '380波段1016A',
    '取值': {
      '电压': '3-3.8',
      '电压步长': 0.1,
      '波长': '375-390',
      '波长步长': 2.5,
      '亮度': '5-25',
      '亮度步长': 2
    }
  },
  {
    '型号': '380波段1030A',
    '取值': {
      '电压': '3.2-4',
      '电压步长': 0.1,
      '波长': '375-390',
      '波长步长': 2.5,
      '亮度': '30-180',
      '亮度步长': 10
    }
  },
  {
    '型号': '395波段0810A',
    '取值': {
      '电压': '3-3.6',
      '电压步长': 0.2,
      '波长': '385-405',
      '波长步长': 2.5,
      '亮度': '5-32',
      '亮度步长': 3
    }
  },
  {
    '型号': '395波段0810B',
    '取值': {
      '电压': '3-3.6',
      '电压步长': 0.2,
      '波长': '385-405',
      '波长步长': 2.5,
      '亮度': '5-48',
      '亮度步长': 3
    }
  },
  {
    '型号': '395波段0922A',
    '取值': {
      '电压': '3-3.5',
      '电压步长': 0.1,
      '波长': '390-405',
      '波长步长': 2.5,
      '亮度': '7-18',
      '亮度步长': 1
    }
  },
  {
    '型号': '395波段3232A',
    '取值': {
      '电压': '3.0-3.4',
      '电压步长': '0.2',
      '波长': '385-405',
      '波长步长': '2.5',
      '亮度': '500-1080',
      '亮度步长': '45,45,50,50,60,70,80,80,100'
    }
  },
  {
    '型号': '395波段1016A',
    '取值': {
      '电压': '3-3.6',
      '电压步长': 0.2,
      '波长': '385-410',
      '波长步长': 2.5,
      '亮度': '18-48',
      '亮度步长': 2
    }
  },
  {
    '型号': '395波段1016B',
    '取值': {
      '电压': '3-3.4',
      '电压步长': 0.2,
      '波长': '385-415',
      '波长步长': 2.5,
      '亮度': '20-48',
      '亮度步长': 2
    }
  },
  {
    '型号': '395波段1016C',
    '取值': {
      '电压': '3-3.6',
      '电压步长': '0.2',
      '波长': '385-415',
      '波长步长': '2.5',
      '亮度': '40-66',
      '亮度步长': '2'
    }
  },
  {
    '型号': '395波段1016D',
    '取值': {
      '电压': '3-3.4',
      '电压步长': '0.2',
      '波长': '385-415',
      '波长步长': '2.5',
      '亮度': '22-48',
      '亮度步长': '2'
    }
  },
  {
    '型号': '395波段1030A',
    '取值': {
      '电压': '3.2-4',
      '电压步长': 0.2,
      '波长': '385-405',
      '波长步长': 2.5,
      '亮度': '140-260',
      '亮度步长': 20
    }
  },
  {
    '型号': '395波段1030B',
    '取值': {
      '电压': '3.27-4',
      '电压步长': '0.16,0.12,0.45',
      '波长': '385-415',
      '波长步长': 2.5,
      '亮度': '140-300',
      '亮度步长': 20
    }
  },
  {
    '型号': '395波段1214A',
    '取值': {
      '电压': '3-3.4',
      '电压步长': 0.2,
      '波长': '385-405',
      '波长步长': 2.5,
      '亮度': '12-40',
      '亮度步长': 2
    }
  },
  {
    '型号': '395波段1214B',
    '取值': {
      '电压': '3-3.4',
      '电压步长': '0.2',
      '波长': '385-405',
      '波长步长': '2.5',
      '亮度': '12-48',
      '亮度步长': '2'
    }
  },
  {
    '型号': '395波段1735A',
    '取值': {
      '电压': '3-3.6',
      '电压步长': '0.2',
      '波长': '385-415',
      '波长步长': '2.5',
      '亮度': '180-300',
      '亮度步长': '20'
    }
  },
  {
    '型号': '410波段1030B',
    '取值': {
      '电压': '3.2-4',
      '电压步长': '0.2',
      '波长': '405-435',
      '波长步长': '2.5',
      '亮度': '140-300',
      '亮度步长': '20'
    }
  },
  {
    '型号': '410波段0810B',
    '取值': {
      '电压': '3-3.4',
      '电压步长': '0.2',
      '波长': '405-435',
      '波长步长': '2.5',
      '亮度': '23-42',
      '亮度步长': '3'
    }
  },
  {
    '型号': '410波段1030A',
    '取值': {
      '电压': '3-3.6',
      '电压步长': '0.2',
      '波长': '405-435',
      '波长步长': '2.5',
      '亮度': '180-300',
      '亮度步长': '20'
    }
  },
  {
    '型号': '410波段1214A',
    '取值': {
      '电压': '2.8-3.4',
      '电压步长': '0.2',
      '波长': '405-435',
      '波长步长': '2.5',
      '亮度': '20-44',
      '亮度步长': '2'
    }
  },
  {
    '型号': '410波段1214B',
    '取值': {
      '电压': '2.8-3.4',
      '电压步长': '0.2',
      '波长': '405-435',
      '波长步长': '2.5',
      '亮度': '20-44',
      '亮度步长': '2'
    }
  },
  {
    '型号': '410波段2235A',
    '取值': {
      '电压': '3-3.6',
      '电压步长': '0.2',
      '波长': '405-435',
      '波长步长': '2.5',
      '亮度': '180-300',
      '亮度步长': '20'
    }
  },
  {
    '型号': '410波段2235B',
    '取值': {
      '电压': '3-3.6',
      '电压步长': '0.2',
      '波长': '405-435',
      '波长步长': '2.5',
      '亮度': '180-300',
      '亮度步长': '20'
    }
  },
  {
    '型号': '395波段4545B',
    '取值': {
      '电压': '2.8-3.4',
      '电压步长': '0.2',
      '波长': '390-430',
      '波长步长': '2.5',
      '亮度': '500-1180',
      '亮度步长': '45,45,50,50,60,70,80,80,100,100'
    }
  },
  {
    '型号': '395波段4545C',
    '取值': {
      '电压': '2.8-3.4',
      '电压步长': '0.2',
      '波长': '385-415',
      '波长步长': '2.5',
      '亮度': '500-1180',
      '亮度步长': '45,45,50,50,60,70,80,80,100,100'
    }
  },
  {
    '型号': '绿光2020A',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '505-535',
      '波长步长': '2.5',
      '亮度': '20-46',
      '亮度步长': '2'
    }
  },
  {
    '型号': '绿光2020A旧',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '505-535',
      '波长步长': '2.5',
      '亮度': '20-46',
      '亮度步长': '2'
    }
  },
  {
    '型号': '绿光2020B',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '510-535',
      '波长步长': '2.5,2.5,2.5,1,1.5,1,1.5,1,1.5,2.5,2.5,2.5,2.5',
      '亮度': '32-46',
      '亮度步长': '2'
    }
  },
  {
    '型号': '绿光1217A',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '510-535',
      '波长步长': '2.5,2.5,2.5,2.5,1,1.5,1,1.5,1,1.5,2.5,2.5,2.5',
      '亮度': '30-44',
      '亮度步长': '2'
    }
  },
  {
    '型号': '绿光2828A',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '505-535',
      '波长步长': '2.5',
      '亮度': '60-110',
      '亮度步长': '5,5,5,5,5,5,5,5,10'
    }
  },
  {
    '型号': '绿光4040A',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '505-535',
      '波长步长': '2.5',
      '亮度': '120-170',
      '亮度步长': '10'
    }
  },
  {
    '型号': '绿光4545A',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '505-535',
      '波长步长': '2.5',
      '亮度': '120-180',
      '亮度步长': '10'
    }
  },
  {
    '型号': '绿光4545B',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '505-535',
      '波长步长': '2.5',
      '亮度': '120-190',
      '亮度步长': '10'
    }
  },
  {
    '型号': '蓝光2828A',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '450-470',
      '波长步长': '2.5',
      '亮度': '440-640',
      '亮度步长': '40'
    }
  },
  {
    '型号': '蓝光4040A',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '450-470',
      '波长步长': '2.5',
      '亮度': '560-720',
      '亮度步长': '40'
    }
  },
  {
    '型号': '蓝光4545A',
    '取值': {
      '电压': '2.5-3.6',
      '电压步长': '0.1',
      '波长': '450-470',
      '波长步长': '2.5',
      '亮度': '560-800',
      '亮度步长': '40'
    }
  },
  {
    '型号': 'RGB蓝光0507A',
    '取值': {
      '电压': '2.7-3',
      '电压步长': '0.3',
      '波长': '462-470',
      '波长步长': '2',
      '亮度': '5-6',
      '亮度步长': '1'
    }
  },
  {
    '型号': 'RGB蓝光0608A',
    '取值': {
      '电压': '2.6-2.95',
      '电压步长': '0.35',
      '波长': '462-470',
      '波长步长': '2',
      '亮度': '5-6.5',
      '亮度步长': '1.5'
    }
  },
  {
    '型号': 'RGB绿光0507A',
    '取值': {
      '电压': '2.6-3',
      '电压步长': '0.4',
      '波长': '516-526',
      '波长步长': '2',
      '亮度': '2.9-3.2',
      '亮度步长': '0.3'
    }
  },
  {
    '型号': 'RGB绿光0608A',
    '取值': {
      '电压': '2.55-2.95',
      '电压步长': '0.4',
      '波长': '516-530',
      '波长步长': '2',
      '亮度': '5.5-9',
      '亮度步长': '3.5'
    }
  },
  {
    '型号': 'RGB红光06CUA',
    '取值': {
      '电压': '1.9-2.3',
      '电压步长': '0.4',
      '波长': '620-625',
      '波长步长': '5',
      '亮度': '400-480',
      '亮度步长': '80'
    }
  },
  {
    '型号': 'RGB红光06BUA',
    '取值': {
      '电压': '1.9-2.3',
      '电压步长': '0.4',
      '波长': '620-625',
      '波长步长': '5',
      '亮度': '420-480',
      '亮度步长': '60'
    }
  },
  {
    '型号': 'RGB红光16BUS',
    '取值': {
      '电压': '1.9-2.3',
      '电压步长': '0.2',
      '波长': '618-625',
      '波长步长': '2,2,3',
      '亮度': '5800-8300',
      '亮度步长': '500'
    }
  },
  {
    '型号': 'RGB红光26CUA',
    '取值': {
      '电压': '1.9-2.3',
      '电压步长': '0.2',
      '波长': '620-625',
      '波长步长': '2,3',
      '亮度': '14000-20000',
      '亮度步长': '2000'
    }
  },
  {
    '型号': 'RGB红光32BUS',
    '取值': {
      '电压': '1.9-2.3',
      '电压步长': '0.2',
      '波长': '620-625',
      '波长步长': '2,3',
      '亮度': '17000-23000',
      '亮度步长': '2000'
    }
  },
  {
    '型号': 'RGB红光40BUA',
    '取值': {
      '电压': '1.9-2.2',
      '电压步长': '0.1',
      '波长': '621-625',
      '波长步长': '2,2',
      '亮度': '18000-20000',
      '亮度步长': '1000'
    }
  },
  {
    '型号': 'RGB红光12CUA',
    '取值': {
      '电压': '1.9-2.2',
      '电压步长': '0.1',
      '波长': '620-625',
      '波长步长': '2,3',
      '亮度': '1200-1800',
      '亮度步长': '200'
    }
  },
  {
    '型号': 'RGB红光1616D',
    '取值': {
      '电压': '2.0-2.3',
      '电压步长': '0.1',
      '波长': '620-625',
      '波长步长': '2.5',
      '亮度': '4400-5200',
      '亮度步长': '400'
    }
  },
  {
    '型号': '410波段1016B',
    '取值': {
      '电压': '3-3.4',
      '电压步长': 0.2,
      '波长': '405-435',
      '波长步长': 2.5,
      '亮度': '20-48',
      '亮度步长': 2
    }
  },
  {
    '型号': '410波段4545C',
    '取值': {
      '电压': '2.8-3.4',
      '电压步长': '0.2',
      '波长': '405-435',
      '波长步长': '2.5',
      '亮度': '500-1180',
      '亮度步长': '45,45,50,50,60,70,80,80,100,100'
    }
  },
].sort((a, b) => {
  if (a.型号 > b.型号) return 1
  else return -1
})

// const baseUrl = 'http://localhost:3651'
const baseUrl = 'https://xg-api.gansemicon.com'

const baseTitle = '芯港半导体数据管理中心'

const defaultOptions = {
  VF1: ['3.0-3.1', '3.1-3.2', '3.0-3.2', '3.2-3.4', '3.4-3.6']
}

const YeWuYuanList = [
  {label: '李忠花', value: '李忠花'},
  {label: '王晨宁', value: '王晨宁'},
  {label: '陈杰', value: '陈杰'},
  {label: '任黎', value: '任黎'},
  {label: '董举', value: '董举'},
  {label: '孙志远', value: '孙志远'},
  {label: '夏少坚', value: '夏少坚'},
  {label: '其他', value: '其他'},
]

export {
  baseUrl,
  baseTitle,
  BoDuanList,
  ZhuangTaiList,
  LaiYuanList,
  ZXDongZuoList,
  CKDongZuoList,
  defaultOptions,
  YeWuYuanList,
  squarePvRanges,
}
